// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import 'styles/fonts';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import 'styles/m3-theme';

// Define the theme object.
// $self-service-theme: mat.define-theme(
//   (
//     color: (
//       theme-type: light,
//       primary: mat.$azure-palette,
//       tertiary: mat.$blue-palette,
//     ),
//     density: (
//       scale: 0,
//     ),
//   )
// );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
    --gfn-dark-blue: #013764;
    --gfn-light-blue: #00b2ca;
    --gfn-sand: #f6f1ef;
    --gfn-cta-red: #ea344d;

    @include mat.all-component-themes($light-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($self-service-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($self-service-theme);

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: 'Source Sans 3', system-ui;
}
* {
    box-sizing: border-box;
}
a {
    color: var(--gfn-light-blue);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--gfn-dark-blue);
}
